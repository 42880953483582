<template>
  <div class="display-flex" style="justify-content: center;align-items: center;">
    <img alt="" src="@/assets/logo.png" style="width: 40px; height:40px;">
    <b style="height: 40px;line-height: 40px;">xvtool</b>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>

</style>