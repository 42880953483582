<template>
  <div class="overflow-y-scroll main-sidebar">
    <ul style="padding: 10px;">
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <!--      <li>-->
      <!--        <router-link to="/test1">test1</router-link>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <router-link to="/test2">test2</router-link>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <router-link to="/test1/1">test1_1</router-link>-->
      <!--      </li>-->
      <!--      <li>-->
      <!--        <router-link to="/test1/2">test1_2</router-link>-->
      <!--      </li>-->
      <li>
        <router-link to="/base64">base64</router-link>
      </li>
      <li>
        <router-link to="/md5">md5</router-link>
      </li>
      <li>
        <router-link to="/mines">扫雷</router-link>
      </li>
      <li>
        <router-link to="/PaintBrush">油漆刷</router-link>
      </li>
    </ul>
  </div>

  <!--  <el-menu :default-openeds="['1', '3']">-->
  <!--    <el-sub-menu index="1">-->
  <!--      <template #title>-->
  <!--        <el-icon>-->
  <!--          <message/>-->
  <!--        </el-icon>-->
  <!--        Navigator One-->
  <!--      </template>-->
  <!--      <el-menu-item-group>-->
  <!--        <template #title>Group 1</template>-->
  <!--        <el-menu-item index="1-1">Option 1</el-menu-item>-->
  <!--        <el-menu-item index="1-2">Option 2</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-menu-item-group title="Group 2">-->
  <!--        <el-menu-item index="1-3">Option 3</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-sub-menu index="1-4">-->
  <!--        <template #title>Option4</template>-->
  <!--        <el-menu-item index="1-4-1">Option 4-1</el-menu-item>-->
  <!--      </el-sub-menu>-->
  <!--    </el-sub-menu>-->
  <!--    <el-sub-menu index="2">-->
  <!--      <template #title>-->
  <!--        <el-icon>-->
  <!--          <Menu/>-->
  <!--        </el-icon>-->
  <!--        Navigator Two-->
  <!--      </template>-->
  <!--      <el-menu-item-group>-->
  <!--        <template #title>Group 1</template>-->
  <!--        <el-menu-item index="2-1">Option 1</el-menu-item>-->
  <!--        <el-menu-item index="2-2">Option 2</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-menu-item-group title="Group 2">-->
  <!--        <el-menu-item index="2-3">Option 3</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-sub-menu index="2-4">-->
  <!--        <template #title>Option 4</template>-->
  <!--        <el-menu-item index="2-4-1">Option 4-1</el-menu-item>-->
  <!--      </el-sub-menu>-->
  <!--    </el-sub-menu>-->
  <!--    <el-sub-menu index="3">-->
  <!--      <template #title>-->
  <!--        <el-icon>-->
  <!--          <setting/>-->
  <!--        </el-icon>-->
  <!--        Navigator Three-->
  <!--      </template>-->
  <!--      <el-menu-item-group>-->
  <!--        <template #title>Group 1</template>-->
  <!--        <el-menu-item index="3-1">Option 1</el-menu-item>-->
  <!--        <el-menu-item index="3-2">Option 2</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-menu-item-group title="Group 2">-->
  <!--        <el-menu-item index="3-3">Option 3</el-menu-item>-->
  <!--      </el-menu-item-group>-->
  <!--      <el-sub-menu index="3-4">-->
  <!--        <template #title>Option 4</template>-->
  <!--        <el-menu-item index="3-4-1">Option 4-1</el-menu-item>-->
  <!--      </el-sub-menu>-->
  <!--    </el-sub-menu>-->
  <!--  </el-menu>-->
</template>

<script setup>

</script>

<style lang="less" scoped>

.main-sidebar {
  //scrollbar-color: #ddd #fff;

  &::-webkit-scrollbar {
    width: 2px;
    //background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    //background-color: #ddd;
    border-radius: 2px;
  }

  //.el-menu {
  //  border: none;
  //}
}

//:deep(.el-sub-menu__title) {
//  padding-left: 5px !important;
//}
//
//:deep(.el-menu-item-group__title) {
//  padding-left: 20px !important;
//}
//
//:deep(.el-menu-item) {
//  padding-left: 35px !important;
//}

ul > li {
  font-size: 18px;
  margin: 20px 0;

  a {
    &:link, &:visited, &:hover, &:active {
      color: #000;
    }
  }
}
</style>