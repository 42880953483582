<template>
  <div class="display-flex" style="height: 100%;justify-content:space-between;align-items: center;padding: 0 10px;">
    <span/>
    <h1 style="">xvtool</h1>
    <img alt="" class="float-right" src="@/assets/avatar.jpg"
         style="width: 40px; height:40px;border-radius: 100%;">
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>

</style>