// App
import {createApp} from 'vue'
import App from '@/App.vue'

// 路由
import router from './router/router'

// ElementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

/**********************************************************************************************************************/

// App
let app = createApp(App);

// 路由
app.use(router)

// ElementPlus
app.use(ElementPlus, {
    locale: zhCn,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

/**********************************************************************************************************************/

// mount
app.mount('#app')