import {createRouter, createWebHistory} from 'vue-router'

export const routes = [
    {
        path: '/',
        component: () => import('@/components/index/IndexPage'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/components/404/404Page'),
        hidden: true
    },
    {
        path: '/about',
        component: () => import('@/components/about/AboutPage'),
        hidden: true
    },
    {
        path: '/test1',
        component: () => import('@/components/test/Test_1'),
        hidden: true
    },
    {
        path: '/test2',
        component: () => import('@/components/test/Test_2'),
        hidden: true
    },
    {
        path: '/test1/1',
        component: () => import('@/components/test/Test_1_1'),
        hidden: true
    },
    {
        path: '/test1/:id',
        component: () => import('@/components/test/Test_1_2'),
        hidden: true
    },
    {
        path: '/base64',
        component: () => import('@/components/encodingTool/Base64Tool.vue'),
        hidden: true
    },
    {
        path: '/md5',
        component: () => import('@/components/encodingTool/Md5Tool.vue'),
        hidden: true
    },
    {
        path: '/mines',
        component: () => import('@/components/game/MinesWeeper.vue'),
        hidden: true
    },
    {
        path: '/PaintBrush',
        component: () => import('@/components/game/PaintBrush.vue'),
        hidden: true
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
        hidden: true
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: [...routes],
})

export default router
