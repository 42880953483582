<template>
  <router-view/>
</template>

<script setup>

</script>

<style lang="less" scoped>

</style>